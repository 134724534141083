import React from "react"
import * as ProjectStyle from './css/portofolio.module.scss'
import * as ProjectStyle2 from './css/portofolio2.module.scss'
import Zoom from 'react-medium-image-zoom'
import Layout from "../components/layout"
import Seo from "../components/seo"

import CKA from '../certificate/nicolas-julian-certified-kubernetes-administrator-cka-certificate-1.jpg'
import TOKOPEDIA from '../certificate/335.jpg'
import TokopediaXSS from '../certificate/Nicolas-julian-(reflected XSS)-1.jpg'
import TokopediaLumos from '../certificate/Nicolas-julian-(lumos service misconfig)-1.jpg'
import TokopediaHarbor from '../certificate/Nicolas-julian-(Harbor service misconfig)-1.jpg'
import TokopediaBuket from '../certificate/Nicolas-julian-(bucket misconfig)-1.jpg'
import TokopediaGeneral from '../certificate/Nicolas-julian-1.jpg'
import CKS from '../certificate/nicolas-julian-626ff04a-cf87-4ef7-ac88-b5026bb862a8-certificate-1.jpg'


import 'react-medium-image-zoom/dist/styles.css'

const Project = () => {
  return (
    <div>
      <Seo title="Portofolio - Nicolas Julian" />
      <Layout>
        <h1>Portofolio</h1>
          <p>Sejak 2019 hingga saat ini, Saya sudah terlibat barbagai macam project. Informasi tentang perusahaan, dan project yang pernah saya tangani bisa ditemukan di halaman ini. Namun untuk <i>active project</i> akan Saya sensor nama perusahaan tersebut hingga benar-benar tidak terlibat dalam project tersebut.</p>
        <h2>Accomplishment Project</h2>
            <ul>
              <li>
                <h3>Manage Service Openstack Iaas Cloud and Kubernetes Cluster - PT █████████ Telecom Tbk (2021 - 2022)</h3>
                <p>Dalam project ini Saya banyak melakukan troubleshooting <i>"Know Issue"</i> seperti <i>one ceph osd failure impatch the whole cluster, connectivity overlay network, HA Kubernetes Failover goes fail, problem with ingress k8s, etc.</i></p>
                <p>Juga melakukan Deployment Monitoring, Rules Alerting, Tuning, dll.</p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>Linux Expertise - PT Bank ███████ Indonesia (2021 - 2022)</h3>
                <p>Saya terlibat pada project ini dari awal mulai, hingga saat ini sebagai Konsultan.</p>
                <p>Dalam Project ini Saya menangani berbagai issue yang sangat tidak biasa. Kasus sperti <b>NFS Hit Bugs, Server Hung</b>, dan melakukan analysis <i>Root Cause</i> sebuah issue bisa terjadi. Kemudian memberikan saran agara issue tersebut tidak terjadi lagi.</p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>Implementation & Manage Service Openstack Iaas Cloud and Kubernetes Cluster - PT Bank ███████ Indonesia (2020 - 2022)</h3>
                <p>Saya terlibat dalam porject ini dari awal masa Percobaan Implemetasi, Real Implemetasi, hingga ke Manage Service.</p>
                <p>Server yang kita Implement & Manage lebih dari 100 Nodes, dari awal project sangat banyak hal yang dilakukan dari membuat deployment tools dengan <b>Ansible</b>  hingga <b>Deploy CEPH, Monitoring, Alerting, Centralize Logs, K8s</b>.
                   Lalu di saat memasuki Manage Service Saya banyak melakukan troubleshooting issue mulai dari <b>Network Overlay, HardCode Software, Tuning, dll.</b></p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>Manage Service Openstack Iaas Cloud - Badan Pengusahaan Kawasan Perdagangan Bebas dan Pelabuhan Bebas █████ (2020 - 2022)</h3>
                <p>Dalam project ini Saya melakukan manage Openstack cluster, troubleshooting, deploy monitoring, troubleshooting VMs, dll.</p>
                <p>Dalam beberapa kasus Saya melakukan troubleshooting di semua in-scope project.</p>
              </li>
            </ul>
            <ul>
              <li>
                <h3>Manage Service Openstack Iaas Cloud and ELK Stacks - ID████████ Service Solution (2020 - 2022)</h3>
                <p>Dalam project ini Saya memastikan bahwa operasional VM, dan ELK Stack berjalan dengan sebagaimana mestinya.
                   Selain itu Saya juga memastikan bahwa Monitoring & Alerting berjalan normal.</p>
                <p>Dalam beberapa kasus Saya melakukan troubleshooting di semua <i>in-scope</i> project.</p>
              </li>
            </ul>
            <ul>
              <li>
              <h3>Manage Service Openstack Iaas Cloud and Roles as DevOps Engineer - PT Sakti Kinerja Kolaborasindo (2019 - 2020)</h3>
              <p>Pekerjaan ini merupakan cikal bakal Saya dalam berkecimpung di dunia IT.
                 Di sini Saya melakukan deployment Openstack & CEPH Cluster mulai dari <i>mounting server</i> ke Data Center hingga VM siap digunakan.</p>
              <p>Tidak cukup sampai VM siap digunakan, setelah itu Saya juga melakukan konfigurasi <i>workflow CI/CD, config Haproxy, Traefik, manage K8s Cluster, dll</i>.</p>
              </li>
            </ul>
        <div id="achievement"><h2>Achievement</h2></div>
          <ul>
          <div className={ProjectStyle2.row}>
            <div className={ProjectStyle2.column}>
              <h3>CKS</h3>
              <Zoom><img className={ProjectStyle2} src={CKS} alt="CKS - Nicolas Julian"/></Zoom>
              <figcaption><a rel="noopener noreferrer" target="_blank" href="https://www.credly.com/badges/7c7644a3-d63f-48cb-9f2a-6562c9ccfbb3/public_url">Sertifikat Kubernetes Security Specialist.</a></figcaption>
            </div>
            <div className={ProjectStyle2.column}>
              <h3>CKA</h3>
              <Zoom><img className={ProjectStyle2} src={CKA} alt="CKA - Nicolas Julian"/></Zoom>
              <figcaption><a rel="noopener noreferrer" target="_blank" href="https://www.credly.com/badges/0f435af3-3986-47d0-9283-6d1727037e34/public_url">Sertifikat Kubernetes Administrator.</a></figcaption>
            </div>
          </div>
            <div className={ProjectStyle.row}>
            <div className={ProjectStyle.column}>
              <h3>Bug Bounty</h3>
              <Zoom><img className={ProjectStyle} src={TOKOPEDIA} alt="Tokopedia Bug Bounty - Nicolas Julian"/></Zoom>
              <figcaption>Sertifikat terakhir dari Tokopedia, "sepertinya jika setiap melapor diberi sertifikat akan kebanyakan".</figcaption>
            </div>
              <div className={ProjectStyle.column}>
                <h3>Bug Bounty</h3>
                <Zoom><img className={ProjectStyle} src={TokopediaGeneral} alt="Tokopedia Bug Bounty - Nicolas Julian"/></Zoom>
                <figcaption>Sertifikat temuan Service Misconfiguration.</figcaption>
              </div>
              <div className={ProjectStyle.column}>
                <h3>Bug Bounty</h3>
                <Zoom><img className={ProjectStyle} src={TokopediaBuket} alt="Tokopedia Bug Bounty - Nicolas Julian"/></Zoom>
                <figcaption>Sertifikat temuan Service Misconfiguration.</figcaption>
              </div>
            </div>
            <div className={ProjectStyle.row}>
              <div className={ProjectStyle.column}>
                <h3>Bug Bounty</h3>
                <Zoom><img className={ProjectStyle} src={TokopediaHarbor} alt="Tokopedia Bug Bounty - Nicolas Julian"/></Zoom>
                <figcaption>Sertifikat temuan Service Misconfiguration.</figcaption>
              </div>
              <div className={ProjectStyle.column}>
                <h3>Bug Bounty</h3>
                <Zoom><img className={ProjectStyle} src={TokopediaLumos} alt="Tokopedia Bug Bounty - Nicolas Julian"/></Zoom>
                <figcaption>Sertifikat temuan Service Misconfiguration.</figcaption>
              </div>
              <div className={ProjectStyle.column}>
                <h3>Bug Bounty</h3>
                <Zoom><img className={ProjectStyle} src={TokopediaXSS} alt="Tokopedia Bug Bounty - Nicolas Julian"/></Zoom>
                <figcaption>Sertifikat yang pertama kali Saya dapatkan. "Reflected XSS".</figcaption>
              </div>
            </div>
          </ul>
      </Layout>
    </div>
  )
}

export default Project
